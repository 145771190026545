import React from 'react'
import Details from '../../../../pages/Details/Details'

const Class10SocialScience = () => {
  return (
    <div>
    <Details courseName="Class 10 Social Science" courseDetails=" Understand the world better with our Class 10 Social Science course! Covering History, Geography, Political Science, and Economics, this course brings important events, global landscapes, and governance systems to life with engaging lessons and real-world applications. Develop critical thinking and a deeper understanding of society. Enroll now and excel in Social Science!"/>
      
    </div>
  )
}

export default Class10SocialScience