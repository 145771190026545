import React from 'react'
import Details from '../../../../pages/Details/Details'

const Class9English = () => {
  return (
    <div>
    <Details courseName="Class 9 English" courseDetails=" Enhance your English skills with our Class 9 English course! Covering grammar, literature, reading comprehension, and creative writing, this course helps students develop strong communication and analytical skills. Engaging lessons, detailed explanations, and interactive exercises make learning English enjoyable and effective. Enroll now and boost your language proficiency!"/>
      
    </div>
  )
}

export default Class9English