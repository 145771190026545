import React from 'react'
import Details from '../../../../../pages/Details/Details'

const Class12CommerceEnglish = () => {
  return (
    <div>
    <Details courseName="Class 12 Commerce English" courseDetails=" Enhance your language and communication skills with our Class 12 Commerce English course! Covering advanced grammar, literature, comprehension, and business communication, this course helps students develop analytical and writing abilities essential for professional success. Engaging lessons, structured practice, and literary analysis make learning English enjoyable and effective. Enroll now and refine your English proficiency!"/>
      
    </div>
  )
}

export default Class12CommerceEnglish