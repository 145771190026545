import React from 'react'
import Details from '../../../../pages/Details/Details'

const Class9SocialScience = () => {
  return (
    <div>
    <Details courseName="Class 9 Social Science" courseDetails=" Understand the world better with our Class 9 Social Science course! Covering History, Geography, Political Science, and Economics, this course brings important events, global landscapes, and governance systems to life with engaging lessons and real-world applications. Develop critical thinking and a deeper understanding of society. Enroll now and explore the world of Social Science!"/>
      
    </div>
  )
}

export default Class9SocialScience