import React from 'react'
import Details from '../../../../pages/Details/Details'

const Class7English = () => {
  return (
    <div>
    <Details courseName="Class 7 English" courseDetails="  Enhance your English skills with our Class 7 English course! Covering grammar, vocabulary, reading comprehension, and creative writing, this course helps students improve their fluency and communication skills. With engaging lessons, interactive exercises, and storytelling, learning English becomes fun and effective. Enroll now and build a strong foundation in English!"/>
      
    </div>
  )
}

export default Class7English