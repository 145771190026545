import React from 'react'
import Details from '../../../../pages/Details/Details'

const Class9Mathematics = () => {
  return (
    <div>
    <Details courseName="Class 9 Mathematics" courseDetails="  Master Mathematics with our Class 9 Mathematics course! Covering Algebra, Geometry, Trigonometry, Probability, and more, this course simplifies complex concepts with step-by-step explanations and practical exercises. Strengthen your problem-solving skills and build a strong foundation for higher studies. Enroll now and make learning math easier and more enjoyable!"/>
      
    </div>
  )
}

export default Class9Mathematics
