import React from 'react'
import Details from '../../../../../pages/Details/Details'

const Class12SciencePhysics = () => {
  return (
    <div>
    <Details courseName="Class 12 Science Physics" courseDetails="  Unravel the mysteries of the universe with our Class 12 Science Physics course! Covering topics like Mechanics, Electromagnetism, Optics, Modern Physics, and Quantum Theory, this course helps students develop strong analytical and problem-solving skills. With interactive experiments, real-world applications, and step-by-step explanations, learning Physics becomes engaging and practical. Enroll now and explore the fundamental laws of nature!"/>
      
    </div>
  )
}

export default Class12SciencePhysics