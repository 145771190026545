import React from 'react'
import Details from '../../../../pages/Details/Details'

const Class6Hindi = () => {
  return (
    <div>
    <Details courseName="Class 6 Hindi" courseDetails="  Strengthen your Hindi language skills with our Class 6 Hindi course! Covering grammar, vocabulary, reading comprehension, and creative writing, this course helps students develop a deep understanding of the language. With engaging lessons and practice exercises, learning Hindi becomes fun and easy. Enroll now and improve your Hindi proficiency!"/>
      
    </div>
  )
}

export default Class6Hindi