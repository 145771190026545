import React from 'react'
import Details from '../../../../../pages/Details/Details'

const Class12HumanitiesSociology = () => {
  return (
    <div>
    <Details courseName="Class 12 Humanities Sociology" courseDetails="  Understand society and its dynamics with our Class 12 Humanities Sociology course! Covering topics like social institutions, culture, stratification, globalization, and social change, this course provides deep insights into human interactions and societal structures. Engaging discussions, case studies, and real-world applications make learning Sociology thought-provoking and relevant. Enroll now and explore the fascinating study of society!"/>
      
    </div>
  )
}

export default Class12HumanitiesSociology