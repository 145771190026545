import React from 'react'
import Details from '../../../pages/Details/Details'

const French = () => {
  return (
    <div>
      <Details courseName="French Spoken" courseDetails="Unlock the beauty of the French language with our French Spoken course. From basic greetings to advanced conversations, this course helps you develop fluency, pronunciation, and confidence. Perfect for travelers, professionals, and language enthusiasts, our structured lessons make learning fun and easy. Start your journey to speaking French like a pro today!"/>
    </div>
  )
}

export default French
