import React from 'react'
import Details from '../../../../pages/Details/Details'

const Class6Science = () => {
  return (
    <div>
    <Details courseName="Class 6 Science" courseDetails="  Discover the wonders of Science with our Class 6 Science course! Covering Physics, Chemistry, and Biology, this course makes learning fun with interactive lessons, experiments, and real-life examples. Strengthen your understanding of scientific concepts and develop curiosity about the world around you. Enroll now and start your science journey today!"/>
      
    </div>
  )
}

export default Class6Science