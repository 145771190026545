import React from 'react'
import Details from '../../../../../pages/Details/Details'

const Class12ScienceChemistry = () => {
  return (
    <div>
    <Details courseName="Class 12 Science Chemistry" courseDetails=" Unlock the secrets of matter with our Class 12 Science Chemistry course! Covering topics like Organic Chemistry, Inorganic Chemistry, Physical Chemistry, and Reaction Mechanisms, this course helps you understand chemical reactions, molecular structures, and the principles governing them. Engaging lessons, experiments, and real-world applications make Chemistry both interesting and practical. Enroll now and master the science of elements and compounds!"/>
      
    </div>
  )
}

export default Class12ScienceChemistry