import React from 'react'
import Details from '../../../../pages/Details/Details'

const Class8English = () => {
  return (
    <div>
    <Details courseName="Class 8 English" courseDetails="  Enhance your English skills with our Class 8 English course! Covering grammar, vocabulary, reading comprehension, and creative writing, this course helps students develop fluency and confidence in communication. Engaging lessons, interactive exercises, and literary appreciation make learning English enjoyable. Enroll now and strengthen your language skills!"/>
      
    </div>
  )
}

export default Class8English