import React from 'react'
import Details from '../../../pages/Details/Details'

const NEETPhysics = () => {
  return (
    <div>
    <Details courseName="NEET Physics" courseDetails="  Excel in NEET Physics with our comprehensive course! Covering Mechanics, Electrodynamics, Thermodynamics, Optics, and Modern Physics, this course simplifies complex theories with step-by-step explanations and problem-solving techniques. Practice NEET-level questions and master time-saving tricks to boost your score. Enroll now and secure your medical dream!"/>
      
    </div>
  )
}

export default NEETPhysics