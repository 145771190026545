import React from 'react'
import Details from '../../../../pages/Details/Details'

const Class8Science = () => {
  return (
    <div>
    <Details courseName="Class 8 Science" courseDetails="  Discover the fascinating world of Science with our Class 8 Science course! Covering Physics, Chemistry, and Biology, this course simplifies complex concepts with engaging lessons, experiments, and real-life applications. Strengthen your scientific thinking and problem-solving skills in a fun and interactive way. Enroll now and explore the wonders of Science!"/>
      
    </div>
  )
}

export default Class8Science