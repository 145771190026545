import React from 'react'
import Details from '../../../pages/Details/Details'

const German = () => {
  return (
    <div>
      <Details courseName="German Spoken" courseDetails=" Learn to speak German fluently with our German Spoken course. This course covers essential vocabulary, pronunciation, and sentence structures to help you communicate confidently. Whether for travel, work, or personal interest, our expert-guided lessons make learning German simple and effective. Enroll now and start speaking German with ease! "/>
    </div>
  )
}

export default German
