import React from 'react'
import Details from '../../../../pages/Details/Details'

const Class6SocialScience = () => {
  return (
    <div>
    <Details courseName="Class 6 Social Science" courseDetails="  Class 6 Social Science introduces students to the fundamentals of history, geography, and civics. They learn about ancient civilizations, key historical events, and important personalities. In geography, they explore the physical features of the Earth, climate, and maps. Civics helps them understand basic concepts of government, democracy, and citizenship. This subject builds a strong foundation for understanding society and the world around us."/>
      
    </div>
  )
}

export default Class6SocialScience