import React from 'react'
import Details from '../../../../../pages/Details/Details'

const Class12CommerceAccountancy = () => {
  return (
    <div>
    <Details courseName="Class 12 Commerce Accountancy" courseDetails="  Master the fundamentals of financial management with our Class 12 Commerce Accountancy course! Covering topics like Partnership Accounts, Company Accounts, Financial Statements, and Accounting Ratios, this course provides a strong foundation in accounting principles. With step-by-step explanations, real-world applications, and practice exercises, you’ll develop essential skills for business and finance. Enroll now and excel in Accountancy!"/>
      
    </div>
  )
}

export default Class12CommerceAccountancy