import React from 'react'
import Details from '../../../../../pages/Details/Details'

const Class12HumanitiesPsychology = () => {
  return (
    <div>
    <Details courseName="Class 12 Humanities Psychology" courseDetails=" Explore the human mind and behavior with our Class 12 Humanities Psychology course! Covering topics like cognitive processes, personality development, mental health, and social behavior, this course provides deep insights into psychological theories and real-world applications. Engaging case studies, experiments, and interactive lessons make learning Psychology fascinating and practical. Enroll now and discover the science behind human thought and emotions!"/>
      
    </div>
  )
}

export default Class12HumanitiesPsychology