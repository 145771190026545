import React from 'react'
import Details from '../../../../pages/Details/Details'

const Class10English = () => {
  return (
    <div>
    <Details courseName="Class 10 English" courseDetails="  Enhance your English proficiency with our Class 10 English course! Covering grammar, literature, reading comprehension, and writing skills, this course helps students develop strong communication and analytical abilities. Engaging lessons, literary analysis, and practice exercises make learning English enjoyable and effective. Enroll now and excel in your studies!"/>
      
    </div>
  )
}

export default Class10English