import React from 'react'
import Details from '../../../../../pages/Details/Details'

const Class12ScienceEnglish = () => {
  return (
    <div>
    <Details courseName="Class 12 Science English" courseDetails="Enhance your communication and analytical skills with our Class 12 Science English course! Covering advanced grammar, comprehension, literature, and technical writing, this course helps students develop critical thinking and effective communication skills. Engaging literary discussions, structured exercises, and real-world applications make learning English both enjoyable and essential for future success. Enroll now and refine your language proficiency!"/>
      
    </div>
  )
}

export default Class12ScienceEnglish