import React from 'react'
import Details from '../../../../pages/Details/Details'

const Class10Mathematics = () => {
  return (
    <div>
    <Details courseName="Class 10 Mathematics" courseDetails=" Master Mathematics with our Class 10 Mathematics course! Covering Algebra, Geometry, Trigonometry, Statistics, and more, this course simplifies complex concepts with step-by-step explanations and practical exercises. Strengthen your problem-solving skills and build a strong foundation for board exams and future studies. Enroll now and make math easy and enjoyable!"/>
      
    </div>
  )
}

export default Class10Mathematics
