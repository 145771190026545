import React from 'react'
import Details from '../../../../pages/Details/Details'

const Class11Science = () => {
  return (
    <div>
    <Details courseName="Class 11 Science" courseDetails="  Unlock the world of Science with our Class 11 Science course! Covering Physics, Chemistry, and Biology, this course delves into advanced concepts with detailed explanations, experiments, and real-world applications. Strengthen your analytical and problem-solving skills to prepare for board exams and competitive tests. Enroll now and take your Science learning to the next level!"/>
      
    </div>
  )
}

export default Class11Science