import React from 'react'
import Details from '../../../../pages/Details/Details'

const Class11SocialScience = () => {
  return (
    <div>
    <Details courseName="Class 11 Social Science" courseDetails=" Gain a deeper understanding of society with our Class 11 Social Science course! Covering History, Geography, Political Science, and Economics, this course explores key events, global landscapes, governance systems, and economic principles. Engaging lessons and real-world applications develop critical thinking and analytical skills. Enroll now and broaden your perspective on the world!"/>
      
    </div>
  )
}

export default Class11SocialScience