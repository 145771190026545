import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './pages/Home/Home';
import './component/assets/css/style.css';
import './component/assets/css/all.min.css';
import './component/assets/css/magnific-popup.min.css';
// import './component/assets/css/aos.css';
import './component/assets/css/bootstrap.min.css';
import './component/assets/css/swiper-bundle.min.css';
import './component/assets/css/animate.min.css';
import './component/assets/css/responsive.css';
import Login from './pages/Login/Login';
import Signup from './pages/Signup/Signup';
import Details from './pages/Details/Details';
import Teacher from './pages/Teacher/Teacher';
import English from './component/Courses/Spoken/English';
import Spanish from './component/Courses/Spoken/Spanish';
import French from './component/Courses/Spoken/French';
import German from './component/Courses/Spoken/German';
import Chinese from './component/Courses/Spoken/Chinese';
import { AuthProvider } from '../src/context/AuthContext';
// class6
import Class6Mathematics from './component/Courses/Class/Class6/Class6Mathematics';
import Class6Science from './component/Courses/Class/Class6/Class6Science';
import Class6English from './component/Courses/Class/Class6/Class6English';
import Class6SocialScience from './component/Courses/Class/Class6/Class6SocialScience';
import Class6Hindi from './component/Courses/Class/Class6/Class6Hindi';
import Class6OptionalSubjects from './component/Courses/Class/Class6/Class6OptionalSubjects';

// class7
import Class7Mathematics from './component/Courses/Class/Class7/Class7Mathematics';
import Class7Science from './component/Courses/Class/Class7/Class7Science';
import Class7English from './component/Courses/Class/Class7/Class7English';
import Class7SocialScience from './component/Courses/Class/Class7/Class7SocialScience';
import Class7Hindi from './component/Courses/Class/Class7/Class7Hindi';
import Class7OptionalSubjects from './component/Courses/Class/Class7/Class7OptionalSubjects';

// class8
import Class8Mathematics from './component/Courses/Class/Class8/Class8Mathematics';
import Class8Science from './component/Courses/Class/Class8/Class8Science';
import Class8English from './component/Courses/Class/Class8/Class8English';
import Class8SocialScience from './component/Courses/Class/Class8/Class8SocialScience';
import Class8Hindi from './component/Courses/Class/Class8/Class8Hindi';
import Class8OptionalSubjects from './component/Courses/Class/Class8/Class8OptionalSubjects';

// class9
import Class9Mathematics from './component/Courses/Class/Class9/Class9Mathematics';
import Class9Science from './component/Courses/Class/Class9/Class9Science';
import Class9English from './component/Courses/Class/Class9/Class9English';
import Class9SocialScience from './component/Courses/Class/Class9/Class9SocialScience';
import Class9Hindi from './component/Courses/Class/Class9/Class9Hindi';
import Class9OptionalSubjects from './component/Courses/Class/Class9/Class9OptionalSubjects';

// class10
import Class10Mathematics from './component/Courses/Class/Class10/Class10Mathematics';
import Class10Science from './component/Courses/Class/Class10/Class10Science';
import Class10English from './component/Courses/Class/Class10/Class10English';
import Class10SocialScience from './component/Courses/Class/Class10/Class10SocialScience';
import Class10Hindi from './component/Courses/Class/Class10/Class10Hindi';
import Class10OptionalSubjects from './component/Courses/Class/Class10/Class10OptionalSubjects';

// class11
import Class11Mathematics from './component/Courses/Class/Class11/Class11Mathematics';
import Class11Science from './component/Courses/Class/Class11/Class11Science';
import Class11English from './component/Courses/Class/Class11/Class11English';
import Class11SocialScience from './component/Courses/Class/Class11/Class11SocialScience';
import Class11Hindi from './component/Courses/Class/Class11/Class11Hindi';
import Class11OptionalSubjects from './component/Courses/Class/Class11/Class11OptionalSubjects';

// class12 Science
import Class12ScinecePhysics from './component/Courses/Class/Class12/Science/SciencePhysics';
import Class12ScineceChemistry from './component/Courses/Class/Class12/Science/ScienceChemistry';
import Class12ScineceBiology from './component/Courses/Class/Class12/Science/ScienceBiology';
import Class12ScineceScienceComputerSciencelnformationTechnology from './component/Courses/Class/Class12/Science/ScienceComputerSciencelnformationTechnology';
import Class12ScienceMathematics from './component/Courses/Class/Class12/Science/ScienceMathematics';
import Class12ScienceEnglish from './component/Courses/Class/Class12/Science/ScienceEnglish';


// class12 Commerce
import Class12CommerceAccounting from './component/Courses/Class/Class12/Commerce/CommerceAccountancy';
import Class12CommerceEnglish from './component/Courses/Class/Class12/Commerce/CommerceEnglish';
import Class12CommerceMathematics from './component/Courses/Class/Class12/Commerce/CommerceMathematics';
import Class12CommerceStatistics from './component/Courses/Class/Class12/Commerce/CommerceStatistics';
import Class12CommerceEconomics from './component/Courses/Class/Class12/Commerce/CommerceEconomics';
import Class12CommerceBusinessStudies from './component/Courses/Class/Class12/Commerce/CommerceBusinessStudies';
// class11 Humanities
import Class12humanitiesEconomics from './component/Courses/Class/Class12/Humanities/HumanitiesEconomics';
import Class12humanitiesGeography from './component/Courses/Class/Class12/Humanities/HumanitiesGeography';
import Class12humanitiesHistory from './component/Courses/Class/Class12/Humanities/HumanitiesHistory';
import Class12humanitiesEnglish from './component/Courses/Class/Class12/Humanities/HumanitiesEnglish';
import Class12humanitiesPoliticalscience from './component/Courses/Class/Class12/Humanities/HumanitiesPoliticalscience';
import Class12humanitiesPsychology from './component/Courses/Class/Class12/Humanities/HumanitiesPsychology';
import Class12humanitiesSociology from './component/Courses/Class/Class12/Humanities/HumanitiesSociology';


// jee
import JEEChemistry from './component/Courses/JEE/JEEChemistry';
import JEEMathematics from './component/Courses/JEE/JEEMathematics';
import JEEPhysics from './component/Courses/JEE/JEEPhysics';

// neet
import NEETChemistry from './component/Courses/NEET/NEETChemistry';
import NEETBiology  from './component/Courses/NEET/NEETBiology';
import NEETPhysics from './component/Courses/NEET/NEETPhysics';


// js


// import './component/js/';
function App() {
  return (
 <>
 <AuthProvider>

 <BrowserRouter>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/login" element={<Login />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/details" element={<Details />} />
      <Route path="/teacher" element={<Teacher />} />

     
        <Route path="/spoken/English" element={<English />} />
        <Route path="/spoken/Spanish" element={<Spanish />} />
        <Route path="/spoken/French" element={<French />} />
        <Route path="/spoken/German" element={<German />} />
        <Route path="/spoken/Chinese" element={<Chinese />} />

        {/* class 6 */}
 {/* class 6 */}
<Route path="/class6/mathematics" element={<Class6Mathematics />} />
<Route path="/class6/science" element={<Class6Science />} />
<Route path="/class6/english" element={<Class6English />} />
<Route path="/class6/socialScience" element={<Class6SocialScience />} />
<Route path="/class6/hindi" element={<Class6Hindi />} />
<Route path="/class6/optionalSubjects" element={<Class6OptionalSubjects />} />

{/* class 7 */}
<Route path="/class7/mathematics" element={<Class7Mathematics />} />
<Route path="/class7/science" element={<Class7Science />} />
<Route path="/class7/english" element={<Class7English />} />
<Route path="/class7/socialScience" element={<Class7SocialScience />} />
<Route path="/class7/hindi" element={<Class7Hindi />} />
<Route path="/class7/optionalSubjects" element={<Class7OptionalSubjects />} />

{/* class 8 */}
<Route path="/class8/mathematics" element={<Class8Mathematics />} />
<Route path="/class8/science" element={<Class8Science />} />
<Route path="/class8/english" element={<Class8English />} />
<Route path="/class8/socialScience" element={<Class8SocialScience />} />
<Route path="/class8/hindi" element={<Class8Hindi />} />
<Route path="/class8/optionalSubjects" element={<Class8OptionalSubjects />} />

{/* class 9 */}
<Route path="/class9/mathematics" element={<Class9Mathematics />} />
<Route path="/class9/science" element={<Class9Science />} />
<Route path="/class9/english" element={<Class9English />} />
<Route path="/class9/socialScience" element={<Class9SocialScience />} />
<Route path="/class9/hindi" element={<Class9Hindi />} />
<Route path="/class9/optionalSubjects" element={<Class9OptionalSubjects />} />

{/* class 10 */}
<Route path="/class10/mathematics" element={<Class10Mathematics />} />
<Route path="/class10/science" element={<Class10Science />} />
<Route path="/class10/english" element={<Class10English />} />
<Route path="/class10/socialScience" element={<Class10SocialScience />} />
<Route path="/class10/hindi" element={<Class10Hindi />} />
<Route path="/class10/optionalSubjects" element={<Class10OptionalSubjects />} />

{/* class 11 */}
<Route path="/class11/mathematics" element={<Class11Mathematics />} />
<Route path="/class11/science" element={<Class11Science />} />
<Route path="/class11/english" element={<Class11English />} />
<Route path="/class11/socialScience" element={<Class11SocialScience />} />
<Route path="/class11/hindi" element={<Class11Hindi />} />
<Route path="/class11/optionalSubjects" element={<Class11OptionalSubjects />} />

{/* class 12 Science */}
<Route path="/class12/science/physics" element={<Class12ScinecePhysics />} />
<Route path="/class12/science/chemistry" element={<Class12ScineceChemistry />} />
<Route path="/class12/science/mathematics" element={<Class12ScienceMathematics />} />
<Route path="/class12/science/biology" element={<Class12ScineceBiology />} />
<Route path="/class12/science/english" element={<Class12ScienceEnglish />} />
<Route path="/class12/science/computerScience" element={<Class12ScineceScienceComputerSciencelnformationTechnology />} />
{/* class 12 Commerce */}
<Route path="/class12/commerce/accountancy" element={<Class12CommerceAccounting />}  />
<Route path="/class12/commerce/businessStudies" element={<Class12CommerceBusinessStudies />} />
<Route path="/class12/commerce/economics" element={<Class12CommerceEconomics />} />
<Route path="/class12/commerce/mathematics" element={<Class12CommerceMathematics />} />
<Route path="/class12/commerce/english" element={<Class12CommerceEnglish />} />
<Route path="/class12/commerce/statistics" element={<Class12CommerceStatistics />} />

{/* class 12 humanities */}
<Route path="/class12/humanities/economics" element={<Class12humanitiesEconomics />}  />
<Route path="/class12/humanities/geography" element={<Class12humanitiesGeography />} />
<Route path="/class12/humanities/history" element={<Class12humanitiesHistory />} />
<Route path="/class12/humanities/english" element={<Class12humanitiesEnglish />} />
<Route path="/class12/humanities/politicalScience" element={<Class12humanitiesPoliticalscience />} />
<Route path="/class12/humanities/psychology" element={<Class12humanitiesPsychology />} />
<Route path="/class12/humanities/sociology" element={<Class12humanitiesSociology />} />


{/* JEE */}
<Route path="/jee/chemistry" element={<JEEChemistry />} />
<Route path="/jee/mathematics" element={<JEEMathematics />} />
<Route path="/jee/physics" element={<JEEPhysics />} />

 {/* NEET */}
<Route path="/neet/chemistry" element={<NEETChemistry />} />
<Route path="/neet/biology" element={<NEETBiology />} />
<Route path="/neet/physics" element={<NEETPhysics />} />
     
      
    </Routes>
  </BrowserRouter>
 </AuthProvider>

 </>
  );
}

export default App;
