import React from 'react'
import Details from '../../../../../pages/Details/Details'

const Class12ScienceMathematics = () => {
  return (
    <div>
    <Details courseName="Class 12 Science Mathematics" courseDetails=" Strengthen your problem-solving skills with our Class 12 Science Mathematics course! Covering topics like Calculus, Algebra, Probability, Vectors, and Differential Equations, this course builds a strong foundation for competitive exams and higher studies. With step-by-step explanations, real-world applications, and practice exercises, mastering Mathematics becomes easier and more effective. Enroll now and take your math skills to the next level!"/>
      
    </div>
  )
}

export default Class12ScienceMathematics