import React from 'react'
import Details from '../../../../../pages/Details/Details'

const Class12ScienceBioIogy = () => {
  return (
    <div>
    <Details courseName="Class 12 Science BioIogy" courseDetails="  Explore the wonders of life with our Class 12 Science Biology course! Covering topics like Genetics, Evolution, Biotechnology, Human Physiology, and Ecology, this course provides a deep understanding of living organisms and biological processes. With engaging diagrams, real-world applications, and practical experiments, Biology becomes both fascinating and easy to grasp. Enroll now and dive into the science of life!"/>
      
    </div>
  )
}

export default Class12ScienceBioIogy