import React from 'react'
import Details from '../../../pages/Details/Details'

const JEEPhysics = () => {
  return (
    <div>
    <Details courseName="JEE Physics" courseDetails="  Achieve excellence in JEE Physics with our expert-led course! Covering Mechanics, Electrodynamics, Thermodynamics, Optics, and Modern Physics, this course simplifies complex theories with clear explanations and problem-solving techniques. Practice with JEE-level questions and master concepts to boost your score. Enroll now and get closer to your IIT dream!"/>
      
    </div>
  )
}

export default JEEPhysics