import React from 'react'
import Details from '../../../../pages/Details/Details'

const Class10Hindi = () => {
  return (
    <div>
    <Details courseName="Class 10 Hindi" courseDetails="  Strengthen your Hindi language skills with our Class 10 Hindi course! Covering grammar, prose, poetry, and creative writing, this course enhances reading, writing, and comprehension abilities. Engaging lessons with literary analysis and practice exercises make learning Hindi interesting and effective. Enroll now and master the beauty of the Hindi language!"/>
      
    </div>
  )
}

export default Class10Hindi