import React from 'react'
import Details from '../../../../pages/Details/Details'

const Class7SocialScience = () => {
  return (
    <div>
    <Details courseName="Class 7 Social Science" courseDetails="  Understand the world around you with our Class 7 Social Science course! Covering History, Geography, and Civics, this course brings past events, world landscapes, and government systems to life with engaging lessons and real-world examples. Develop critical thinking and a deeper knowledge of society. Enroll now and explore the world of Social Science!"/>
      
    </div>
  )
}

export default Class7SocialScience