import React from 'react'
import Details from '../../../../pages/Details/Details'

const Class8SocialScience = () => {
  return (
    <div>
    <Details courseName="Class 8 Social Science" courseDetails="  Explore history, geography, and civics with our Class 8 Social Science course! Learn about past civilizations, the Earth's landscapes, and how governments function through engaging lessons and real-world examples. This course helps develop critical thinking and a deeper understanding of society. Enroll now and make Social Science interesting and fun!"/>
      
    </div>
  )
}

export default Class8SocialScience