import React from 'react'
import Details from '../../../../../pages/Details/Details'

const Class12CommerceStatistics = () => {
  return (
    <div>
    <Details courseName="Class 12 Commerce Statistics" courseDetails="Master data analysis and interpretation with our Class 12 Commerce Statistics course! Covering topics like Measures of Central Tendency, Probability, Correlation, Regression, and Index Numbers, this course helps students develop analytical and decision-making skills. With real-world applications and step-by-step explanations, understanding statistics becomes easier and more practical. Enroll now and enhance your statistical knowledge!"/>
      
    </div>
  )
}

export default Class12CommerceStatistics