import React from 'react'
import Details from '../../../../pages/Details/Details'

const Class11English = () => {
  return (
    <div>
    <Details courseName="Class 11 English" courseDetails="  Enhance your English skills with our Class 11 English course! Covering advanced grammar, literature, reading comprehension, and writing techniques, this course helps students develop strong analytical and communication abilities. Engaging lessons, literary analysis, and structured practice exercises make learning English more effective and enjoyable. Enroll now and refine your language skills!"/>
      
    </div>
  )
}

export default Class11English