import React from 'react'
import Details from '../../../../../pages/Details/Details'

const Class12HumanitiesEconomics = () => {
  return (
    <div>
    <Details courseName="Class 12 Humanities Economics" courseDetails=" Explore economic concepts and policies with our Class 12 Humanities Economics course! Covering Microeconomics and Macroeconomics, this course dives into topics like demand and supply, national income, inflation, economic growth, and government policies. Engaging lessons, real-world applications, and analytical exercises make learning Economics insightful and practical. Enroll now and develop a strong understanding of the economy!"/>
      
    </div>
  )
}

export default Class12HumanitiesEconomics