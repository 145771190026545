import React from 'react'
import Details from '../../../../pages/Details/Details'

const Class7OptionalSubjects = () => {
  return (
    <div>
    <Details courseName="Class 7 Optional Subjects" courseDetails="  Explore new skills and knowledge with our Class 7 Optional Subjects course! Covering subjects like Computer Science, General Knowledge, Environmental Studies, and more, this course encourages curiosity and creativity. With interactive lessons and engaging activities, students can discover their interests and expand their learning. Enroll now and make learning exciting!"/>
      
    </div>
  )
}

export default Class7OptionalSubjects