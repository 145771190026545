import React from 'react'
import Details from '../../../../../pages/Details/Details'

const Class12HumanitiesEnglish = () => {
  return (
    <div>
    <Details courseName="Class 12 Humanities English" courseDetails=" Enhance your language and literary skills with our Class 12 Humanities English course! Covering prose, poetry, advanced grammar, and creative writing, this course helps students develop strong communication, analytical, and critical thinking abilities. Engaging lessons, literary discussions, and structured practice exercises make learning English enjoyable and effective. Enroll now and refine your language proficiency!"/>
      
    </div>
  )
}

export default Class12HumanitiesEnglish