import React from 'react'
import Details from '../../../../pages/Details/Details'

const Class6English = () => {
  return (
    <div>
    <Details courseName="Class 6 English" courseDetails="  Build a strong foundation in English with our Class 6 English course! Covering grammar, vocabulary, reading comprehension, and writing skills, this course makes learning fun and interactive. With engaging lessons and practice exercises, students develop confidence in speaking and writing. Enroll now and enhance your English skills with ease!"/>
      
    </div>
  )
}

export default Class6English