import React from 'react'
import Details from '../../../../pages/Details/Details'

const Class9Science = () => {
  return (
    <div>
    <Details courseName="Class 9 Science" courseDetails="  Explore the wonders of Science with our Class 9 Science course! Covering Physics, Chemistry, and Biology, this course simplifies complex concepts with engaging lessons, experiments, and real-life applications. Strengthen your analytical skills and build a strong foundation for higher studies. Enroll now and make Science learning fun and interactive!"/>
      
    </div>
  )
}

export default Class9Science