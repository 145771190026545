import React from 'react'
import Details from '../../../../../pages/Details/Details'

const Class12HumanitiesGeography = () => {
  return (
    <div>
    <Details courseName="Class 12 Humanities Geography" courseDetails="  Explore the world with our Class 12 Humanities Geography course! Covering Physical, Human, and Economic Geography, this course dives into topics like climate change, population dynamics, urbanization, and resource management. With interactive maps, case studies, and real-world applications, you'll develop a deeper understanding of the Earth's landscapes and global issues. Enroll now and expand your geographical knowledge!"/>
      
    </div>
  )
}

export default Class12HumanitiesGeography