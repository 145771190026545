import React from 'react'
import Details from '../../../../../pages/Details/Details'

const Class12CommerceMathematics = () => {
  return (
    <div>
    <Details courseName="Class 12 Commerce Mathematics" courseDetails="Build a strong foundation in Mathematics with our Class 12 Commerce Mathematics course! Covering topics like Calculus, Probability, Linear Programming, and Financial Mathematics, this course enhances analytical and problem-solving skills. With step-by-step explanations, real-world applications, and practical exercises, mastering math becomes easier and more effective. Enroll now and excel in Commerce Mathematics!"/>
      
    </div>
  )
}

export default Class12CommerceMathematics