import React from 'react'
import Details from '../../../../../pages/Details/Details'

const Class12CommerceBusinessStudies = () => {
  return (
    <div>
    <Details courseName="Class 12 Commerce Business Studies" courseDetails="  Develop a strong understanding of business principles with our Class 12 Commerce Business Studies course! Covering topics like Management, Marketing, Business Environment, Financial Management, and Entrepreneurship, this course provides in-depth knowledge of how businesses operate. With real-world case studies, structured lessons, and practical applications, you'll gain essential skills for success in the corporate world. Enroll now and take your business knowledge to the next level!"/>
      
    </div>
  )
}

export default Class12CommerceBusinessStudies