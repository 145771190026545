import React from 'react'
import Details from '../../../../../pages/Details/Details'

const Class12HumanitiesPoliticalscience = () => {
  return (
    <div>
    <Details courseName="Class 12 Humanities Political science" courseDetails=" Understand governance and political systems with our Class 12 Humanities Political Science course! Covering topics like the Indian Constitution, democracy, international relations, and political ideologies, this course helps students analyze political structures and global affairs. Engaging discussions, case studies, and real-world applications make learning Political Science insightful and thought-provoking. Enroll now and strengthen your understanding of politics and governance!"/>
      
    </div>
  )
}

export default Class12HumanitiesPoliticalscience