import React from 'react'
import Details from '../../../../pages/Details/Details'

const Class7Hindi = () => {
  return (
    <div>
    <Details courseName="Class 7 Hindi" courseDetails="  prove your Hindi language skills with our Class 7 Hindi course! Covering grammar, vocabulary, reading comprehension, and creative writing, this course helps students develop a strong command of the language. Engaging lessons, interesting stories, and practice exercises make learning Hindi enjoyable and effective. Enroll now and boost your Hindi proficiency!"/>
      
    </div>
  )
}

export default Class7Hindi