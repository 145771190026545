import React, { useEffect, useState } from "react";
import Header from "../../component/Header/Header";
import Footer from "../../component/Footer/Footer";
import { Link } from "react-router-dom";
import { Toaster, toast } from 'sonner';
import { jwtDecode } from "jwt-decode";
const Signup = () => {
   const [user, setUser] = useState({
     name: "",
     email: "",
     number: "",
     password: "",
   });

   const [role, setRole] = useState(null);

   const handleChange=(e)=>{
      const {name,value}=e.target
      setUser({...user,[name]:value})
   }
   const userRegister=async(e)=>{
    e.preventDefault();


      const signupdata=user
     const res=await fetch(`https://onlineeudcationapi.njssolutions.in/signup `,{
      method:"POST",
      headers:{
        "Content-Type":"application/Json"
      },
      body:JSON.stringify(signupdata)
      
     }).then(res=>res.json())
     .then((data)=>{
      if(data.success){
       console.log(data);
       
        localStorage.setItem("token", data.usertoken)
        localStorage.setItem("role", data.user.role)
        toast.success('Resignation Successfully ')
         window.location.href = "/";
      }else{
         // Handle failure response
         toast.error(data.message || "Registration failed");
      }
   
       })
       .catch((err)=>{
        console.log(err)
        toast.error(err);
       })

      
   }
//   useEffect(() => {
//     const token2 = localStorage.getItem("role");
//     if (token2) {
//      const decoded = jwtDecode(token2);
//      setRole(decoded);
//  }
//  }, []);
  
 console.log(role);
 
  return (
    <div>
        <Toaster  position='top-center'   toastOptions={{
          style: {
            borderRadius: "10px",
            padding: "15px",
          },
        }} />
      <Header />
      <section class="breadcum">
        <div class="container">
          <div class="breadcum-content">
            <h2 class="title">Sign Up</h2>
            <h4 class="para">
              <a href="index.html">Home</a> / Sign Up
            </h4>
          </div>
        </div>
      </section>
      <section class="login-section">
        <div class="signin">
          <div class="content">
            <h2 class="login-title">Sign Up</h2>

            <form class="form" onSubmit={userRegister}>
              <div class="inputBox">
                <input 
                type="text"
                placeholder="Name" 
                name="name" 
                value={user.name}
                onChange={handleChange}
                required />
              </div>
              <div class="inputBox">
                <input
                  type="email"
                  placeholder="email "
                  name="email"
                  value={user.email}   
                  onChange={handleChange}
                  required
                />
              </div>

              <div class="inputBox">
                <input
                  type="text"
                  placeholder="Number"
                  name="number"
                  value={user.number}
                  onChange={handleChange}
                  required
                />
              </div>
              <div class="inputBox">
                <input
                  type="password"
                  placeholder="Password"
                  name="password"
                  value={user.password}
                  onChange={handleChange}
                  required
                />
              </div>

              <div class="links">
                {" "}
                <Link to="/login">Login</Link>
              </div>
              <div class="all-btn v2">
                <button type="submit" href="#" class="btn-p btn-blue v1 rounded">
                  Sign Up Now
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Signup;
