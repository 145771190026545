import React from 'react'
import Details from '../../../../pages/Details/Details'

const Class8Mathematics = () => {
  return (
    <div>
    <Details courseName="Class 8 Mathematics" courseDetails="  Strengthen your mathematical skills with our Class 8 Mathematics course! Covering Algebra, Geometry, Mensuration, Data Handling, and more, this course simplifies complex concepts with step-by-step explanations and practical exercises. Develop problem-solving abilities and boost confidence in math. Enroll now and make learning mathematics easy and fun!"/>
      
    </div>
  )
}

export default Class8Mathematics
