import React from 'react'
import Details from '../../../../pages/Details/Details'

const Class11OptionalSubjects = () => {
  return (
    <div>
    <Details courseName="Class 11 Optional Subjects" courseDetails=" Explore new opportunities with our Class 11 Optional Subjects course! Covering subjects like Computer Science, Entrepreneurship, Physical Education, Fine Arts, and more, this course allows students to develop specialized skills and discover their interests. With interactive lessons and practical applications, learning becomes engaging and meaningful. Enroll now and expand your academic horizons!"/>
      
    </div>
  )
}

export default Class11OptionalSubjects