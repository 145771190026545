import React from "react";
import logo from "../assets/img/logo/logo.png";
import { Link } from "react-router-dom";
const Header = () => {
  function showmenu() {
    document
      .querySelector(".mobile-menu-wrapper")
      .classList.toggle("body-visible");
  }
  function logout() {
    localStorage.removeItem("token");
  }
  return (
    <div>
      <header class="nav-header header-layout1">
        {/* <!--==============================
      Header One Top Area
      ==============================--> */}
        <div class="header-one-top-area">
          <div class="container">
            <div class="header-top-content v1">
              <p class="discount-the-month">
                We are giving best offers for new students 25% discount this
                month
              </p>
              <ul class="user-login">
                <li>
                  <i class="my-icon icon-user"></i>
                </li>
                <li>
                  {localStorage.getItem("token") ? (
                    <>
                      {" "}
                      <Link to="/login" onClick={logout}>
                        Logout
                      </Link>
                    </>
                  ) : (
                    <>
                      {" "}
                      <Link to="/login">Login</Link>
                    </>
                  )}
                </li>
                <li>
                  <Link to="/signup">Register</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        {/* <!--==============================
      Top Content Info
      ==============================--> */}
        <div class="top-content-info">
          <div class="container">
            <div class="row align-items-center justify-content-between">
              <div class="col-lg-2 d-flex align-items-center justify-content-between ">
                <div class="logo-img">
                  <Link to="/">
                    <img src={logo} alt="edupls-icon" />
                  </Link>
                </div>
                <div class="navbar-right d-inline-flex d-lg-none">
                  <button
                    type="button"
                    class="menu-toggle icon-btn"
                    onClick={showmenu}
                  >
                    <i class="fa-solid fa-bars"></i>
                  </button>
                </div>
              </div>

              <div class="col-lg-6">
                <div class="nav-middle-content">
                  <div class="content-location">
                    <div class="location-icon">
                      <i class="fa-solid fa-location-dot"></i>
                    </div>
                    <div class="info-data">
                      <a class="title" href="#">
                        Topper Grade Location
                      </a>
                      <p class="para text">Chandigarh</p>
                    </div>
                  </div>
                  <div class="content-address">
                    <div class="address-icon">
                      <i class="fa-regular fa-envelope"></i>
                    </div>
                    <div class="info-data">
                      <a class="title" href="#">
                        Email Address
                      </a>
                      <p class="para text">learn@toppergrade.com</p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-4">
                <div class="top-nav-search-option">
                  <form>
                    <input type="search" placeholder="Enter Courses" />
                    <button class="search-btn-nav" type="submit">
                      <i class="fa-solid fa-magnifying-glass"></i>
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!--==============================
      Sticky Wrapper
    ==============================--> */}
        <div class="sticky-wrapper">
          <div class="menu-area">
            <div class="container">
              <div class="menu-area-content">
                <div class="row align-items-center justify-content-between">
                  <div class="col-auto">
                    <nav class="main-menu d-none d-lg-inline-block">
                      <ul>
                        <li class="">
                          <a href="#">Spoken</a>
                          <ul class="sub-menu">
                            <li>
                              <Link to="/spoken/English"> English</Link>
                            </li>
                            <li>
                              <Link to="/spoken/Spanish">Spanish</Link>
                            </li>
                            <li>
                              <Link to="/spoken/French">French</Link>
                            </li>
                            <li>
                              <Link to="/spoken/German">German</Link>
                            </li>
                            <li>
                              <Link to="/spoken/Chinese">Mandarin Chinese</Link>
                            </li>
                          </ul>
                        </li>
                        <li>
                          <a href="about-us.html">Classes</a>

                          <ul class="sub-menu">
                            <li>
                              <a href="courses-grid.html">Class 6</a>
                              <ul class="sub-menu">
                                <li>
                                  <Link to="/class6/mathematics">
                                    Mathematics
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/class6/science">Science</Link>
                                </li>
                                <li>
                                  <Link to="/class6/english">English</Link>
                                </li>
                                <li>
                                  <Link to="/class6/socialScience">
                                    Social Science
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/class6/hindi">Hindi</Link>
                                </li>
                                <li>
                                  <Link to="/class6/optionalSubjects">
                                    Optional Subjects
                                  </Link>
                                </li>
                              </ul>
                            </li>
                            <li>
                              <a href="#">Class 7</a>
                              <ul class="sub-menu">
                                {/* class7 */}
                                <li>
                                  <Link to="/class7/mathematics">
                                    Mathematics
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/class7/science">Science</Link>
                                </li>
                                <li>
                                  <Link to="/class7/english">English</Link>
                                </li>
                                <li>
                                  <Link to="/class7/socialScience">
                                    Social Science
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/class7/hindi">Hindi</Link>
                                </li>
                                <li>
                                  <Link to="/class7/optionalSubjects">
                                    Optional Subjects
                                  </Link>
                                </li>
                              </ul>
                            </li>

                            <li>
                              <a href="#">Class 8</a>
                              <ul class="sub-menu">
                                {/* class8 */}
                                <li>
                                  <Link to="/class8/mathematics">
                                    Mathematics
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/class8/science">Science</Link>
                                </li>
                                <li>
                                  <Link to="/class8/english">English</Link>
                                </li>
                                <li>
                                  <Link to="/class8/socialScience">
                                    Social Science
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/class8/hindi">Hindi</Link>
                                </li>
                                <li>
                                  <Link to="/class8/optionalSubjects">
                                    Optional Subjects
                                  </Link>
                                </li>
                              </ul>
                            </li>
                            <li>
                              <a href="#">Class 9</a>
                              <ul class="sub-menu">
                                {/* class9 */}
                                <li>
                                  <Link to="/class9/mathematics">
                                    Mathematics
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/class9/science">Science</Link>
                                </li>
                                <li>
                                  <Link to="/class9/english">English</Link>
                                </li>
                                <li>
                                  <Link to="/class9/socialScience">
                                    Social Science
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/class9/hindi">Hindi</Link>
                                </li>
                                <li>
                                  <Link to="/class9/optionalSubjects">
                                    Optional Subjects
                                  </Link>
                                </li>
                              </ul>
                            </li>
                            <li>
                              <a href="#">Class 10</a>
                              <ul class="sub-menu">
                                {/* class10 */}
                                <li>
                                  <Link to="/class10/mathematics">
                                    Mathematics
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/class10/science">Science</Link>
                                </li>
                                <li>
                                  <Link to="/class10/english">English</Link>
                                </li>
                                <li>
                                  <Link to="/class10/socialScience">
                                    Social Science
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/class10/hindi">Hindi</Link>
                                </li>
                                <li>
                                  <Link to="/class10/optionalSubjects">
                                    Optional Subjects
                                  </Link>
                                </li>
                              </ul>
                            </li>

                            <li>
                              <a href="#">Class 11</a>
                              <ul class="sub-menu">
                                {/* class11 */}
                                <li>
                                  <Link to="/class11/mathematics">
                                    Mathematics
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/class11/science">Science</Link>
                                </li>
                                <li>
                                  <Link to="/class11/english">English</Link>
                                </li>
                                <li>
                                  <Link to="/class11/socialScience">
                                    Social Science
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/class11/hindi">Hindi</Link>
                                </li>
                                <li>
                                  <Link to="/class11/optionalSubjects">
                                    Optional Subjects
                                  </Link>
                                </li>
                              </ul>
                            </li>

                            <li>
                              <a href="courses-grid.html">Class 12</a>
                              <ul class="sub-menu">
                                <li className="sub-menu">
                                  <Link to="#">Science </Link>

                                  <ul class="sub-menu">
                                    <li className="sub-menu">
                                      <Link to="/class12/science/physics">Physics</Link>
                                    </li>
                                    <li>
                                      <Link to='/class12/science/chemistry'>Chemistry</Link>
                                    </li>
                                    <li>
                                      <Link to="/class12/science/mathematics">
                                        Mathematics
                                      </Link>
                                    </li>
                                    <li className="sub-menu">
                                      <Link to="/class12/science/biology">Biology</Link>
                                    </li>
                                    <li>
                                      <Link to="/class12/science/computerScience">
                                        Computer Science/Information Technology
                                      </Link>
                                    </li>
                                    <li>
                                      <Link to="/class12/science/english">English</Link>
                                    </li>
                                  </ul>
                                </li>
                                <li>
                                  <a href="#">Commerce</a>
                                  <ul class="sub-menu">
                                    <li className="sub-menu">
                                      <Link to="/class12/commerce/accountancy">
                                        Accountancy
                                      </Link>
                                    </li>
                                    <li>
                                      <Link to="/class12/commerce/businessStudies">
                                        Business Studies
                                      </Link>
                                    </li>
                                    <li>
                                      <Link to="/class12/commerce/economics">
                                        Economics
                                      </Link>
                                    </li>
                                    <li className="sub-menu">
                                      <Link to="/class12/commerce/mathematics">
                                        Mathematics
                                      </Link>
                                    </li>
                                    <li>
                                      <Link to="/class12/commerce/english">English</Link>
                                    </li>
                                    <li>
                                      <Link to="/class12/commerce/statistics">
                                        Statistics
                                      </Link>
                                    </li>
                                  </ul>
                                </li>
                                <li>
                                  <a href="#">Humanities</a>
                                  <ul class="sub-menu">
                                    <li className="sub-menu">
                                      <Link to="/class12/humanities/history">History</Link>
                                    </li>
                                    <li>
                                      <Link to="/class12/humanities/geography">Geography</Link>
                                    </li>
                                    <li>
                                      <Link to="/class12/humanities/politicalScience">
                                        Political Science
                                      </Link>
                                    </li>
                                    <li className="sub-menu">
                                      <Link to="/class12/humanities/psychology">Psychology</Link>
                                    </li>
                                    <li>
                                      <Link to="/class12/humanities/sociology">Sociology</Link>
                                    </li>
                                    <li>
                                      <Link to="/class12/humanities/economics">
                                        Economics
                                      </Link>
                                    </li>
                                    <li>
                                      <Link to="/class12/humanities/english">English</Link>
                                    </li>
                                  </ul>
                                </li>
                              </ul>
                            </li>
                          </ul>
                        </li>

                        <li>
                          <a href="about-us.html">Neet</a>
                          <ul class="sub-menu">
                            <li className="sub-menu">
                              <Link to="/neet/physics">Physics</Link>
                            </li>
                            <li>
                              <Link to="/neet/chemistry">Chemistry</Link>
                            </li>
                            <li>
                              <Link to="/neet/biology">Biology</Link>
                            </li>
                          </ul>
                        </li>

                        <li>
                          <a href="about-us.html">Jee</a>
                          <ul class="sub-menu">
                            <li className="sub-menu">
                              <Link to="/jee/physics">Physics</Link>
                            </li>
                            <li>
                              <Link to="/jee/chemistry">Chemistry</Link>
                            </li>
                            <li>
                              <Link to="/jee/mathematics">Mathematics</Link>
                            </li>
                          </ul>
                        </li>

                        <li class="">
                          <a href="#contact">Contact Us</a>
                        </li>
                      </ul>
                    </nav>
                  </div>
                  <div class="col-auto">
                    <div class="menu-area-right-content">
                      <ul class="menu-social">
                        <li>
                          <a href="https://www.facebook.com/profile.php?id=61571265475362&mibextid=ZbWKwL">
                            <i class="fa-brands fa-facebook"></i>
                          </a>
                        </li>
                        <li>
                          <a href="https://www.instagram.com/toppers_grade/profilecard/?igsh=MTRjem43NjQwbmZjNQ== ">
                            <i class="fa-brands fa-square-instagram"></i>
                          </a>
                        </li>
                        <li>
                          <a href="https://youtube.com/@toppersgradeofficial?si=l28exIJocZzeFalt">
                            <i class="fa-brands fa-youtube"></i>
                          </a>
                        </li>
                      </ul>
                      <div class="menu-contact-number">
                        <a href="#" class="contact-number">
                          +917979746915
                        </a>
                      </div>
                      <div class="all-btn">
                        <Link
                          to={"/teacher"}
                          href="contact.html"
                          class="btn-p v3"
                        >
                          Become a Teacher
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!--==============================
      Mobile Menu Wrapper
    ==============================--> */}
        <div class="mobile-menu-wrapper ">
          <div class="mobile-menu-area text-center">
            <button class="menu-toggle" onClick={showmenu}>
              <i class="fa-solid fa-xmark"></i>
            </button>
            <div class="mobile-logo">
              <a href="index.html">
                <img src={logo} style={{ width: "130px" }} alt="Edupls-icon" />
              </a>
            </div>
            <div class="mobile-menu">
              <ul>
              <li class="">
                          <a href="#">Spoken</a>
                          <ul class="sub-menu">
                            <li>
                              <Link to="/spoken/English"> English</Link>
                            </li>
                            <li>
                              <Link to="/spoken/Spanish">Spanish</Link>
                            </li>
                            <li>
                              <Link to="/spoken/French">French</Link>
                            </li>
                            <li>
                              <Link to="/spoken/German">German</Link>
                            </li>
                            <li>
                              <Link to="/spoken/Chinese">Mandarin Chinese</Link>
                            </li>
                          </ul>
                        </li>
                        <li>
                          <a href="about-us.html">Classes</a>

                          <ul class="sub-menu">
                            <li>
                              <a href="courses-grid.html">Class 6</a>
                              <ul class="sub-menu">
                                <li>
                                  <Link to="/class6/mathematics">
                                    Mathematics
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/class6/science">Science</Link>
                                </li>
                                <li>
                                  <Link to="/class6/english">English</Link>
                                </li>
                                <li>
                                  <Link to="/class6/socialScience">
                                    Social Science
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/class6/hindi">Hindi</Link>
                                </li>
                                <li>
                                  <Link to="/class6/optionalSubjects">
                                    Optional Subjects
                                  </Link>
                                </li>
                              </ul>
                            </li>
                            <li>
                              <a href="#">Class 7</a>
                              <ul class="sub-menu">
                                {/* class7 */}
                                <li>
                                  <Link to="/class7/mathematics">
                                    Mathematics
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/class7/science">Science</Link>
                                </li>
                                <li>
                                  <Link to="/class7/english">English</Link>
                                </li>
                                <li>
                                  <Link to="/class7/socialScience">
                                    Social Science
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/class7/hindi">Hindi</Link>
                                </li>
                                <li>
                                  <Link to="/class7/optionalSubjects">
                                    Optional Subjects
                                  </Link>
                                </li>
                              </ul>
                            </li>

                            <li>
                              <a href="#">Class 8</a>
                              <ul class="sub-menu">
                                {/* class8 */}
                                <li>
                                  <Link to="/class8/mathematics">
                                    Mathematics
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/class8/science">Science</Link>
                                </li>
                                <li>
                                  <Link to="/class8/english">English</Link>
                                </li>
                                <li>
                                  <Link to="/class8/socialScience">
                                    Social Science
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/class8/hindi">Hindi</Link>
                                </li>
                                <li>
                                  <Link to="/class8/optionalSubjects">
                                    Optional Subjects
                                  </Link>
                                </li>
                              </ul>
                            </li>
                            <li>
                              <a href="#">Class 9</a>
                              <ul class="sub-menu">
                                {/* class9 */}
                                <li>
                                  <Link to="/class9/mathematics">
                                    Mathematics
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/class9/science">Science</Link>
                                </li>
                                <li>
                                  <Link to="/class9/english">English</Link>
                                </li>
                                <li>
                                  <Link to="/class9/socialScience">
                                    Social Science
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/class9/hindi">Hindi</Link>
                                </li>
                                <li>
                                  <Link to="/class9/optionalSubjects">
                                    Optional Subjects
                                  </Link>
                                </li>
                              </ul>
                            </li>
                            <li>
                              <a href="#">Class 10</a>
                              <ul class="sub-menu">
                                {/* class10 */}
                                <li>
                                  <Link to="/class10/mathematics">
                                    Mathematics
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/class10/science">Science</Link>
                                </li>
                                <li>
                                  <Link to="/class10/english">English</Link>
                                </li>
                                <li>
                                  <Link to="/class10/socialScience">
                                    Social Science
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/class10/hindi">Hindi</Link>
                                </li>
                                <li>
                                  <Link to="/class10/optionalSubjects">
                                    Optional Subjects
                                  </Link>
                                </li>
                              </ul>
                            </li>

                            <li>
                              <a href="#">Class 11</a>
                              <ul class="sub-menu">
                                {/* class11 */}
                                <li>
                                  <Link to="/class11/mathematics">
                                    Mathematics
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/class11/science">Science</Link>
                                </li>
                                <li>
                                  <Link to="/class11/english">English</Link>
                                </li>
                                <li>
                                  <Link to="/class11/socialScience">
                                    Social Science
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/class11/hindi">Hindi</Link>
                                </li>
                                <li>
                                  <Link to="/class11/optionalSubjects">
                                    Optional Subjects
                                  </Link>
                                </li>
                              </ul>
                            </li>

                            <li>
                              <a href="courses-grid.html">Class 12</a>
                              <ul class="sub-menu">
                                <li className="sub-menu">
                                  <a href="courses-grid.html">Science </a>

                                  <ul class="sub-menu">
                                    <li className="sub-menu">
                                      <a href="courses-grid.html">Physics</a>
                                    </li>
                                    <li>
                                      <a href="courses-list.html">Chemistry</a>
                                    </li>
                                    <li>
                                      <a href="courses-details.html">
                                        Mathematics
                                      </a>
                                    </li>
                                    <li className="sub-menu">
                                      <a href="courses-grid.html">Biology</a>
                                    </li>
                                    <li>
                                      <a href="courses-list.html">
                                        Computer Science/Information Technology
                                      </a>
                                    </li>
                                    <li>
                                      <a href="courses-details.html">English</a>
                                    </li>
                                  </ul>
                                </li>
                                <li>
                                  <a href="courses-list.html">Commerce</a>
                                  <ul class="sub-menu">
                                    <li className="sub-menu">
                                      <a href="courses-grid.html">
                                        Accountancy
                                      </a>
                                    </li>
                                    <li>
                                      <a href="courses-list.html">
                                        Business Studies
                                      </a>
                                    </li>
                                    <li>
                                      <a href="courses-details.html">
                                        Economics
                                      </a>
                                    </li>
                                    <li className="sub-menu">
                                      <a href="courses-grid.html">
                                        Mathematics
                                      </a>
                                    </li>
                                    <li>
                                      <a href="courses-list.html">English</a>
                                    </li>
                                    <li>
                                      <a href="courses-details.html">
                                        Statistics
                                      </a>
                                    </li>
                                  </ul>
                                </li>
                                <li>
                                  <a href="courses-details.html">Humanities</a>
                                  <ul class="sub-menu">
                                    <li className="sub-menu">
                                      <a href="courses-grid.html">History</a>
                                    </li>
                                    <li>
                                      <a href="courses-list.html">Geography</a>
                                    </li>
                                    <li>
                                      <a href="courses-details.html">
                                        Political Science
                                      </a>
                                    </li>
                                    <li className="sub-menu">
                                      <a href="courses-grid.html">Psychology</a>
                                    </li>
                                    <li>
                                      <a href="courses-list.html">Sociology</a>
                                    </li>
                                    <li>
                                      <a href="courses-details.html">
                                        Economics
                                      </a>
                                    </li>
                                    <li>
                                      <a href="courses-details.html">English</a>
                                    </li>
                                  </ul>
                                </li>
                              </ul>
                            </li>
                          </ul>
                        </li>

                <li class="">
                  <a href="#contact">Contact Us</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};

export default Header;
