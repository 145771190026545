import React from 'react'
import Details from '../../../../pages/Details/Details'

const Class6OptionalSubjects = () => {
  return (
    <div>
    <Details courseName="Class 6 Optional Subjects" courseDetails="  Explore and enhance your skills with our Class 6 Optional Subjects course! Covering a variety of subjects like Computer Science, General Knowledge, Environmental Studies, and more, this course encourages curiosity and holistic learning. With interactive lessons and engaging activities, students can discover new interests and strengthen their knowledge. Enroll now and make learning exciting!"/>
      
    </div>
  )
}

export default Class6OptionalSubjects