import React from 'react'
import Details from '../../../../../pages/Details/Details'

const Class12ScienceComputerSciencelnformationTechnology = () => {
  return (
    <div>
    <Details courseName="Class 12 Science Computer Science " courseDetails="Master the world of programming and technology with our Class 12 Science Computer Science course! Covering topics like Python programming, Data Structures, Database Management, Networking, and Artificial Intelligence basics, this course equips students with essential coding and computational skills. With hands-on projects, real-world applications, and interactive lessons, learning Computer Science becomes exciting and career-oriented. Enroll now and step into the future of technology!"/>
      
    </div>
  )
}

export default Class12ScienceComputerSciencelnformationTechnology