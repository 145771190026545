import React from 'react'
import Details from '../../../../pages/Details/Details'

const Class11Mathematics = () => {
  return (
    <div>
    <Details courseName="Class 11 Mathematics" courseDetails=" Master Mathematics with our Class 11 Mathematics course! Covering Algebra, Calculus, Trigonometry, Probability, and more, this course simplifies complex concepts with step-by-step explanations and practical exercises. Strengthen your problem-solving skills and build a strong foundation for competitive exams and higher studies. Enroll now and make learning math easier and more effective!"/>
      
    </div>
  )
}

export default Class11Mathematics
