import React from 'react'
import Details from '../../../../pages/Details/Details'

const Class11Hindi = () => {
  return (
    <div>
    <Details courseName="Class 11 Hindi" courseDetails="  trengthen your Hindi language skills with our Class 11 Hindi course! Covering advanced grammar, prose, poetry, and creative writing, this course enhances reading, writing, and analytical abilities. Engaging literary analysis, thought-provoking discussions, and structured exercises make learning Hindi enriching and enjoyable. Enroll now and master the depth of the Hindi language!"/>
      
    </div>
  )
}

export default Class11Hindi