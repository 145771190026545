import React from 'react'
import Details from '../../../../pages/Details/Details'

const Class7Mathematics = () => {
  return (
    <div>
    <Details courseName="Class 7 Mathematics" courseDetails="  Build a strong foundation in Mathematics with our Class 7 Mathematics course! Covering Algebra, Geometry, Fractions, Decimals, Data Handling, and more, this course simplifies concepts with clear explanations and practical exercises. Strengthen problem-solving skills and boost confidence in math. Enroll now and make learning math fun and easy!"/>
      
    </div>
  )
}

export default Class7Mathematics
