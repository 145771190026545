import React from 'react'
import Details from '../../../pages/Details/Details'

const JEEChemistry = () => {
  return (
    <div>
    <Details courseName="JEE Chemistry" courseDetails="  Crack JEE Chemistry with our expert-designed course! Covering Physical, Organic, and Inorganic Chemistry, this course simplifies complex concepts, reactions, and problem-solving techniques. With in-depth explanations, shortcuts, and practice questions, you'll master the subject and boost your JEE score. Enroll now and take a step closer to your IIT dream!"/>
      
    </div>
  )
}

export default JEEChemistry