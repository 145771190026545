import React from 'react'
import Details from '../../../pages/Details/Details'

const JEEMathematics = () => {
  return (
    <div>
    <Details courseName="JEE Mathematics" courseDetails="  Master JEE Mathematics with our comprehensive course! Covering Algebra, Calculus, Trigonometry, Coordinate Geometry, and more, this course simplifies complex concepts with step-by-step explanations and shortcut techniques. Practice with high-quality problems and boost your problem-solving speed for a top JEE rank. Enroll now and strengthen your math skills!"/>
      
    </div>
  )
}

export default JEEMathematics