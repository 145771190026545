import React from 'react'
import Details from '../../../../pages/Details/Details'

const Class8Hindi = () => {
  return (
    <div>
    <Details courseName="Class 8 Hindi" courseDetails="  Improve your Hindi language skills with our Class 8 Hindi course! Covering grammar, vocabulary, comprehension, and creative writing, this course enhances reading, writing, and speaking abilities. Engaging stories, poems, and exercises make learning Hindi interesting and effective. Enroll now and build a strong command of the language!"/>
      
    </div>
  )
}

export default Class8Hindi