import React from "react";
import details_img from "../../component/assets/img/courses-details/download.png";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Footer from "../../component/Footer/Footer";
import Header from "../../component/Header/Header";
const Teacher = () => {
  return (
    <>
      <Header />
      <div>
        <section class="breadcum">
          <div class="container">
            <div class="breadcum-content">
              <h2 class="title">Tutor Policy for Toppers Grade</h2>
            </div>
          </div>
        </section>
        <section class="my-120 @">
          <div class="container-lg">
            <div class="row">
              <div class="col-lg-8">
                <div class="courses-details-left-content">
                  <div class="top-content">
                    <div class="courses-img">
                      <img src={details_img} alt="courses-img" />
                    </div>
                    <h3 class="title">Welcome to Toppers Grade! </h3>
                  </div>
                  <Tabs>
                    <div class="middle-content">
                      <nav>
                        <TabList>
                          <div class="nav nav-tabs" id="nav-tab" role="tablist">
                            <Tab>
                              <button
                                class="nav-link "
                                id="nav-overview-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#nav-overview"
                                type="button"
                                role="tab"
                                aria-controls="nav-overview"
                                aria-selected="true"
                              >
                                Overview
                              </button>
                            </Tab>
                            <Tab>
                              <button
                                class="nav-link "
                                id="nav-reviews-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#nav-reviews"
                                type="button"
                                role="tab"
                                aria-controls="nav-reviews"
                                aria-selected="false"
                                tabindex="-1"
                              >
                                Reviews
                              </button>
                            </Tab>
                          </div>
                        </TabList>
                      </nav>
                      <div class="tab-content" id="nav-tabContent">
                        <TabPanel>
                          <div
                            class="tab-pane  "
                            id="nav-overview"
                            role="tabpanel"
                            aria-labelledby="nav-overview-tab"
                          >
                            <div class="overview-content">
                              <h3 class="title">Tutor Overview</h3>
                              <p class="para">
                                We are excited to have you as a part of our
                                tutoring community. To ensure a high-quality
                                learning experience for students and a
                                professional environment for tutors, we have
                                established the following policies:
                              </p>
                              {/* <p class="para2">
                    Take your expertise in [subject] to the next level with our
                    Advanced Subject Mastery course. libe Designed st learners
                    with a solid foundation in [subject], this course delves
                    into advanced topics techniques,linevfe equipping you with
                    the knowledge and skills to become a subject expert jon
                    covered include [list dde advanced topics], and you'll
                    engage in [mention any practical , or type complex
                    problem-solv exercises subtends for.
                  </p> */}
                              {/* <h4 class="mini-title">What You Will Learn?</h4>
                  <p class="para">
                    There are several reasons why you should choose us:
                  </p> */}
                              <div class="service-cards">
                                <div class="card-content">
                                  <h4 class="card-title">
                                    <i class="fa-solid fa-check"></i>Tutor
                                    Eligibility
                                  </h4>
                                  <ul className="tutotList">
                                    <li class="para">
                                      Tutors must have expertise in the subjects
                                      they teach, verified through
                                      certifications, degrees, or relevant
                                    </li>
                                    <li class="para">
                                      Tutors must submit valid ID proof and
                                      relevant qualification documents during
                                      the onboarding process.
                                    </li>
                                    <li class="para">
                                      Proficiency in English or the language of
                                      instruction is mandatory.
                                    </li>
                                  </ul>
                                </div>
                                <div class="card-content">
                                  <h4 class="card-title">
                                    <i class="fa-solid fa-check"></i>
                                    Professional Conduct
                                  </h4>
                                  <ul class="tutotList">
                                    <li class="para">
                                      Tutors must conduct themselves
                                      professionally, treating students and
                                      their families with respect.
                                    </li>
                                    <li class="para">
                                      Discrimination, harassment, or
                                      inappropriate behavior of any kind is
                                      strictly prohibited.
                                    </li>
                                    <li class="para">
                                      Tutors must adhere to the agreed-upon
                                      schedule and communicate promptly if any
                                      changes are needed.
                                    </li>
                                  </ul>
                                </div>

                                <div class="card-content">
                                  <h4 class="card-title">
                                    <i class="fa-solid fa-check"></i>Session
                                    Guidelines
                                  </h4>
                                  <ul class="tutotList">
                                    <li class="para">
                                      Each session should focus on the student's
                                      academic growth and follow a structured
                                      plan.
                                    </li>
                                    <li class="para">
                                      Tutors are expected to begin sessions on
                                      time and deliver the full duration of the
                                      scheduled class.
                                    </li>
                                    <li class="para">
                                      Recording or sharing student information
                                      or session content without consent is
                                      prohibited.
                                    </li>
                                  </ul>
                                </div>

                                <div class="card-content">
  <h4 class="card-title">
    <i class="fa-solid fa-check"></i>Cancellations and Rescheduling
  </h4>
  <ul class="tutotList">
    <li class="para">
      Tutors must notify students and the platform at least 24 hours in advance for cancellations or rescheduling.
    </li>
    <li class="para">
      Frequent cancellations or no-shows may result in penalties or removal from the platform.
    </li>
  </ul>
</div>

<div class="card-content">
  <h4 class="card-title">
    <i class="fa-solid fa-check"></i>Performance and Reviews
  </h4>
  <ul class="tutotList">
    <li class="para">
      Tutors will be evaluated based on student feedback, attendance, and teaching effectiveness.
    </li>
    <li class="para">
      Consistently low ratings or complaints may lead to probation or deactivation of the tutor's profile.
    </li>
  </ul>
</div>
<div class="card-content">
  <h4 class="card-title">
    <i class="fa-solid fa-check"></i>Termination of Services
  </h4>
  <ul class="tutotList">
    <li class="para">
      Tutors may terminate their association with Toppers Grade by providing a written notice of 14 days.
    </li>
    <li class="para">
      Toppers Grade reserves the right to deactivate a tutor's account for non-compliance with the policies.
    </li>
  </ul>
</div>
<div class="card-content">
  <h4 class="card-title">
    <i class="fa-solid fa-check"></i>Legal and Compliance
  </h4>
  <ul class="tutotList">
    <li class="para">
      Tutors are responsible for ensuring their teaching methods comply with the local education regulations.
    </li>
    <li class="para">
      Tutors agree to indemnify Toppers Grade against any legal claims arising from their services.
    </li>
  </ul>
</div>

                              </div>
                              <p class="para">
                              By joining the Toppers Grade platform, tutors agree to adhere to these policies.

                              </p>
                              <p class="para">
                              For any questions or concerns, please contact support@toppersgrade.com.


                              </p>
                              <p class="para">
                              Let's work together to create an enriching learning experience for every student!

                              </p>

                            </div>
                          </div>
                        </TabPanel>

                        <TabPanel>
                          <div
                            class="tab-pane "
                            id="nav-reviews"
                            role="tabpanel"
                            aria-labelledby="nav-reviews-tab"
                          >
                            <h4 class="title">Student Ratings & Reviews</h4>
                            <ul class="reviews-content-inner">
                              <li>
                                <div class="rating-point-content">
                                  <div class="rating-points">4.7 </div>
                                  <ul class="star">
                                    <li>
                                      <i class="my-icon icon-star"></i>
                                    </li>
                                    <li>
                                      <i class="my-icon icon-star"></i>
                                    </li>
                                    <li>
                                      <i class="my-icon icon-star"></i>
                                    </li>
                                    <li>
                                      <i class="my-icon icon-star"></i>
                                    </li>
                                    <li>
                                      <i class="my-icon icon-star"></i>
                                    </li>
                                  </ul>
                                  <div class="">Total 2 Ratings </div>
                                </div>
                              </li>
                              <li>
                                <div class="yearly-star-rating">
                                  <div class="year-title">5 years ago</div>
                                  <div class="star-rating-content">
                                    <ul class="star">
                                      <li>
                                        <i class="my-icon icon-star"></i>
                                      </li>
                                      <li>
                                        <i class="my-icon icon-star"></i>
                                      </li>
                                      <li>
                                        <i class="my-icon icon-star"></i>
                                      </li>
                                      <li>
                                        <i class="my-icon icon-star"></i>
                                      </li>
                                      <li>
                                        <i class="fa-regular fa-star"></i>{" "}
                                      </li>
                                    </ul>
                                    <p class="para">
                                      The course is extraordinary!! <br />
                                      It explains everything from A to Z
                                      regarding Nutrition and also there are
                                      some very valuable workout tips. <br />
                                      Great job!{" "}
                                    </p>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div class="yearly-star-rating">
                                  <div class="year-title">4 years ago</div>
                                  <div class="star-rating-content">
                                    <ul class="star">
                                      <li>
                                        <i class="my-icon icon-star"></i>
                                      </li>
                                      <li>
                                        <i class="my-icon icon-star"></i>
                                      </li>
                                      <li>
                                        <i class="my-icon icon-star"></i>
                                      </li>
                                      <li>
                                        <i class="my-icon icon-star"></i>
                                      </li>
                                      <li>
                                        <i class="my-icon icon-star"></i>
                                      </li>
                                    </ul>
                                    <p class="para">
                                      The course is extraordinary!! <br />
                                      It explains everything from A to Z
                                      regarding Nutrition and also there are
                                      some very valuable workout tips. <br />
                                      Great job!
                                    </p>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </TabPanel>
                      </div>
                    </div>
                  </Tabs>
                </div>
              </div>
              <div class="col-lg-4">
                <div class="courses-details-right-content">
                  <div class="side-bar-top-content">
                    <section class="login-section">
                      <div class="signin">
                        <div class="content">
                          <h2 class="login-title">Get In Touch</h2>

                          <div class="form">
                            <div class="inputBox">
                              <input
                                type="text"
                                placeholder="F Name"
                                required
                              />
                            </div>
                            <div class="inputBox">
                              <input
                                type="email"
                                placeholder="Email"
                                required
                              />
                            </div>

                            <div class="inputBox">
                              <input
                                type="text"
                                placeholder="Phone Number"
                                required
                              />
                            </div>
                            <div class="inputBox">
                              <input
                                type="text"
                                placeholder="Subject"
                                required
                              />
                            </div>

                            <div class="all-btn v2">
                              <a href="#" class="btn-p btn-blue v1 rounded">
                                Submit
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default Teacher;
