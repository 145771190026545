import React, { useState } from 'react'
import Header from '../../component/Header/Header'
import Footer from '../../component/Footer/Footer'
import { Toaster, toast } from 'sonner';
import {Link} from 'react-router-dom'

const Login = () => {
   const [loginuser,setLoginUser]=useState({
      email:"",
      password:""
   })
   
   const handleChange=(e)=>{
      const {name,value}=e.target
      setLoginUser({...loginuser,[name]: value})
   }

   const handleloginUser=async (e)=>{
      e.preventDefault()
     try {
      const userlogindata=loginuser
      const res=await fetch("https://onlineeudcationapi.njssolutions.in/login",{
         method:"POST",
         headers:{
            "Content-Type":"application/Json"
         },
         body:JSON.stringify(userlogindata)
      }).then(res=> res.json())
      .then((data)=>{
         if(data.success){
            localStorage.setItem("token",data.usertoken)
            toast.success('Login Successfully ')
            window.location.href='/'
         }else{
            toast.error(data.message || "Loign failed")
         }
         
      })
     } catch (error) {
      toast.error(error)
      console.log(error);
      
     }
   }
  return (
    <div>
    <Toaster  position='top-center'   toastOptions={{
          style: {
            borderRadius: "10px",
            padding: "15px",
          },
        }} />
    <Header/>
    <section class="breadcum">
<div class="container">
    <div class="breadcum-content">
        <h2 class="title">Log In</h2>
        <h4 class="para"><a href="index.html">Home</a> / Log In</h4>
    </div>
</div>
</section>
<section class="login-section">
   <div class="signin">
      
      <div class="content">
         <h2 class="login-title">Log In</h2>
         <form class="form" onSubmit={handleloginUser}>
            <div class="inputBox">
               <input type="text" 
               name='email'
               placeholder="Email"
               value={loginuser.email}
               onChange={handleChange}
                required/>
            </div>
            <div class="inputBox">
               <input type="text" 
               placeholder="Password"
               name='password'
               value={loginuser.password}
               onChange={handleChange}
                required/>
            </div>
            <div class="links"> <a href="#">Forgot Password</a> <Link to="/signup" >Signup</Link>
            </div>
            <div class="all-btn v2">
               <button type="submit" class="btn-p btn-blue v1 rounded">Log in Now</button>
            </div>
         </form>
      </div>

   </div>

</section>
      <Footer/>
    </div>
  )
}

export default Login
