import React, { useState } from 'react'
import Header from '../../component/Header/Header'
import Footer from '../../component/Footer/Footer'
import details_img from '../../component/assets/img/courses-details/download.png'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { Toaster, toast } from 'sonner';
const Details = ({courseName, courseDetails}) => {
 const [coursedata, setCourseData] =useState({
    name: "",
    email: "",
    number: "",
    subject: ""
 })

 const handleChange=(e)=>{
    const {name,value}=e.target
    setCourseData({...coursedata,[name]:value})
 }
 console.log(coursedata);
 

 const handlesubmit=async(e)=>{
  e.preventDefault()
const res=await fetch("https://onlineeudcationapi.njssolutions.in/createcourse",{
  method:"POST",
  headers:{
    "Content-Type":"application/Json"
  },
  body:JSON.stringify(coursedata)
}).then(res=>res.json())
.then((data)=>{
  if(data){
    console.log(data);
    toast.success("Course Enrolled Successfully")
  }
  else{
    toast.error("Something went wrong")
  }
  
})

 }
  return (
    <div>
      <Header/>
      <Toaster  position='top-center'   toastOptions={{
          style: {
            borderRadius: "10px",
            padding: "15px",
          },
        }} />
      <section class="breadcum">
<div class="container">
    <div class="breadcum-content">
        <h2 class="title">{courseName}</h2>
        
    </div>
</div>
</section>
<section class="my-120 @">
  <div class="container-lg">
    <div class="row">
      <div class="col-lg-8">
        <div class="courses-details-left-content">
          <div class="top-content">
            <div class="courses-img">
              <img src={details_img} alt="courses-img" />
            </div>
            <h3 class="title">{courseName}</h3>
            
          </div>
          <Tabs>
          <div class="middle-content">
            <nav>
            <TabList>
              <div class="nav nav-tabs" id="nav-tab" role="tablist">
              <Tab>
              <button class="nav-link " id="nav-overview-tab" data-bs-toggle="tab"
                  data-bs-target="#nav-overview" type="button" role="tab" aria-controls="nav-overview"
                  aria-selected="true">
                  Overview
                </button>
              </Tab>
             <Tab>
             <button class="nav-link " id="nav-reviews-tab" data-bs-toggle="tab" data-bs-target="#nav-reviews"
                  type="button" role="tab" aria-controls="nav-reviews" aria-selected="false" tabindex="-1">
                  Reviews
                </button>
             </Tab>
               
             
              
              
                
              </div>
              </TabList>
            </nav>
            <div class="tab-content" id="nav-tabContent">
            <TabPanel>
            <div class="tab-pane  " id="nav-overview" role="tabpanel"
                aria-labelledby="nav-overview-tab">
                <div class="overview-content">
                  <h3 class="title">Courses Overview</h3>
                  <p class="para">
                  {courseDetails}
                  </p>
                  {/* <p class="para2">
                    Take your expertise in [subject] to the next level with our
                    Advanced Subject Mastery course. libe Designed st learners
                    with a solid foundation in [subject], this course delves
                    into advanced topics techniques,linevfe equipping you with
                    the knowledge and skills to become a subject expert jon
                    covered include [list dde advanced topics], and you'll
                    engage in [mention any practical , or type complex
                    problem-solv exercises subtends for.
                  </p> */}
                  <h4 class="mini-title">What You Will Learn?</h4>
                  <p class="para">
                    There are several reasons why you should choose us:
                  </p>
                  <div class="service-cards">
                    <div class="card-content">
                      <h4 class="card-title">
                        <i class="fa-solid fa-check"></i>Diverse Range of
                        Services:
                      </h4>
                      <p class="para">
                        Are you an educator looking to your teaching skills or
                        expand your expart curriculum sutdents for you....
                      </p>
                    </div>
                    <div class="card-content">
                      <h4 class="card-title">
                        <i class="fa-solid fa-check"></i>Diverse Range of
                        Services:
                      </h4>
                      <p class="para">
                        Are you an educator looking to your teaching skills or
                        expand your expart curriculum sutdents for you....
                      </p>
                    </div>
                  </div>
                  <p class="para">
                    Are you new to [course topic Our Course Name for Beginners
                    course is the perfect starting ob point. you're looking to
                    switch careers or simply explore a new interest, this course
                    provides a se gentle introduction to the world of course
                    topic Topics position time
                  </p>
                </div>
              </div>
            </TabPanel>
              
            <TabPanel>
            <div class="tab-pane " id="nav-reviews" role="tabpanel" aria-labelledby="nav-reviews-tab">
                <h4 class="title">Student Ratings & Reviews</h4>
                <ul class="reviews-content-inner">
                  <li>
                    <div class="rating-point-content">
                      <div class="rating-points">4.7 </div>
                      <ul class="star">
                        <li><i class="my-icon icon-star"></i></li>
                        <li><i class="my-icon icon-star"></i></li>
                        <li><i class="my-icon icon-star"></i></li>
                        <li><i class="my-icon icon-star"></i></li>
                        <li><i class="my-icon icon-star"></i></li>
                      </ul>
                      <div class="">Total 2 Ratings </div>
                    </div>
                  </li>
                  <li>
                    <div class="yearly-star-rating">
                      <div class="year-title">5 years ago</div>
                      <div class="star-rating-content">
                        <ul class="star">
                          <li><i class="my-icon icon-star"></i></li>
                          <li><i class="my-icon icon-star"></i></li>
                          <li><i class="my-icon icon-star"></i></li>
                          <li><i class="my-icon icon-star"></i></li>
                          <li><i class="fa-regular fa-star"></i> </li>
                        </ul>
                        <p class="para">
                          The course is extraordinary!! <br/>
                          It explains everything from A to Z regarding Nutrition and also there are some very valuable
                          workout tips. <br/>
                          Great job! </p>
                      </div>

                    </div>
                  </li>
                  <li>
                    <div class="yearly-star-rating">
                      <div class="year-title">4 years ago</div>
                      <div class="star-rating-content">
                        <ul class="star">
                          <li><i class="my-icon icon-star"></i></li>
                          <li><i class="my-icon icon-star"></i></li>
                          <li><i class="my-icon icon-star"></i></li>
                          <li><i class="my-icon icon-star"></i></li>
                          <li><i class="my-icon icon-star"></i></li>
                        </ul>
                        <p class="para">
                          The course is extraordinary!! <br/>
                          It explains everything from A to Z regarding Nutrition and also there are some very valuable
                          workout tips. <br/>
                          Great job!</p>
                      </div>

                    </div>
                  </li>
                </ul>
              </div>
            </TabPanel>
              
            </div>
          </div>
          </Tabs>
         
        </div>
      </div>
      <div class="col-lg-4">
        <div class="courses-details-right-content">
          <div class="side-bar-top-content">
          <section class="login-section">

<div class="signin">

   <div class="content">

      <h2 class="login-title">Get In Touch</h2>

      <form class="form" onSubmit={handlesubmit}>

         <div class="inputBox">

            <input type="text" 
            placeholder=" Name"
            name='name'
            value={coursedata.name} 
            onChange={handleChange}
            
            required/>

         </div>
         <div class="inputBox">

            <input type="email" 
            placeholder="Email" 
            name='email'
            value={coursedata.email}
            onChange={handleChange}
            required/>

         </div>

         <div class="inputBox">

            <input type="text" 
            placeholder="Phone Number"
            name='number'
            value={coursedata.number}
            onChange={handleChange} 
            required/>

         </div>
         <div class="inputBox">
         <select 
         name="subject"
          id="subject"
          value={coursedata.subject} 
          onChange={handleChange} // Add the onChange handler
          class="inputBox">
         <option >Select Subject</option>
            <option value={courseName} >{courseName}</option>
            
         </select>


          

         </div>

        
         <div class="all-btn v2">
            <button type='submit' class="btn-p btn-blue v1 rounded">Submit</button>
         </div>

      </form>

   </div>

</div>

</section>
            
            
          </div>
          <div class="side-bar-bottom-content">
            <h3 class="title">Working Hours</h3>
            <ul class="working-hours">
              <li>
                <i class="fa-regular fa-clock"></i>
                <p class="para">Monday - Friday 1.00 - 2:00 pm</p>
              </li>
              <li>
                <i class="fa-regular fa-clock"></i>
                <p class="para">Saturday 8.00 - 12:00 pm</p>
              </li>
              <li>
                <i class="fa-regular fa-clock"></i>
                <p class="para">Sunday closed</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
    <Footer/>
    </div>
  )
}

export default Details
