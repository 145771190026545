import React from 'react'
import Details from '../../../../../pages/Details/Details'

const Class12HumanitiesHistory = () => {
  return (
    <div>
    <Details courseName="Class 12 Humanities History" courseDetails=" Uncover the past with our Class 12 Humanities History course! Explore ancient civilizations, medieval societies, and modern world events through engaging lessons, historical sources, and critical analysis. Understand the impact of revolutions, wars, cultural movements, and political changes on today's world. Enroll now and develop a deeper appreciation for history and its influence on the present!"/>
      
    </div>
  )
}

export default Class12HumanitiesHistory