import React from 'react'
import Details from '../../../../pages/Details/Details'

const Class6Mathematics = () => {
  return (
    <div>
    <Details courseName="Class 6 Mathematics" courseDetails="  Master the fundamentals of Mathematics with our Class 6 Mathematics course! Covering Numbers, Algebra, Geometry, Fractions, Decimals, and more, this course simplifies concepts with step-by-step explanations and interactive exercises. Build problem-solving skills and gain confidence in math. Enroll now and make learning math fun and easy!"/>
      
    </div>
  )
}

export default Class6Mathematics
