import React from 'react'
import Details from '../../../pages/Details/Details'

const Spanish = () => {
  return (
    <div>
      <Details courseName=" Spanish Spoken" courseDetails="  Speak Spanish with confidence with our Spanish Spoken course! Learn essential vocabulary, grammar, and pronunciation to communicate fluently. Whether you're traveling, working, or just passionate about the language, our interactive lessons make learning easy and fun. Start your Spanish journey today and speak like a native!"/>
    </div>
  )
}

export default Spanish
