import React from 'react'
import Details from '../../../pages/Details/Details'

const NEETBiology = () => {
  return (
    <div>
    <Details courseName="NEET Biology" courseDetails="  Crack NEET Biology with our in-depth course! Covering Botany and Zoology, this course simplifies NCERT concepts, diagrams, and important topics with easy explanations and mnemonics. Get expert guidance, practice with NEET-level questions, and improve your speed and accuracy. Enroll now to boost your NEET score and secure your medical dream!"/>
      
    </div>
  )
}

export default NEETBiology