import React from 'react'
import Details from '../../../../pages/Details/Details'

const Class9OptionalSubjects = () => {
  return (
    <div>
    <Details courseName="Class 9 Optional Subjects" courseDetails=" Expand your knowledge with our Class 9 Optional Subjects course! Covering subjects like Computer Science, General Knowledge, Environmental Studies, and more, this course helps students develop new skills and interests. With interactive lessons and practical activities, learning becomes engaging and fun. Enroll now and explore exciting new subjects!"/>
      
    </div>
  )
}

export default Class9OptionalSubjects