import React from 'react'
import Details from '../../../pages/Details/Details'

const Chinese = () => {
  return (
    <div>
    <Details courseName=" Chinese Spoken" courseDetails=" Learn to speak Chinese fluently with our Chinese Spoken course. This course covers essential vocabulary, pronunciation, and sentence structures to help you communicate effectively. Whether you're traveling, doing business, or just passionate about learning a new language, our step-by-step lessons make it easy. Join now and start speaking Chinese with confidence!"/>
      
    </div>
  )
}

export default Chinese
