import React from 'react'
import Details from '../../../../pages/Details/Details'

const Class7Science = () => {
  return (
    <div>
    <Details courseName="Class 7 Science" courseDetails="  Unleash your curiosity with our Class 7 Science course! Covering Physics, Chemistry, and Biology, this course simplifies complex concepts through interactive lessons, experiments, and real-life applications. Strengthen your scientific thinking and problem-solving skills in a fun and engaging way. Enroll now and start your journey of scientific discovery!"/>
      
    </div>
  )
}

export default Class7Science