import React from 'react'
import Details from '../../../../pages/Details/Details'

const Class10Science = () => {
  return (
    <div>
    <Details courseName="Class 10 Science" courseDetails="  Explore the fascinating world of Science with our Class 10 Science course! Covering Physics, Chemistry, and Biology, this course simplifies complex concepts with engaging lessons, experiments, and real-life applications. Strengthen your analytical skills and build a solid foundation for board exams and higher studies. Enroll now and make Science learning exciting and effective!"/>
      
    </div>
  )
}

export default Class10Science