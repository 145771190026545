import React from 'react'
import Details from '../../../../../pages/Details/Details'

const Class12CommerceEconomics = () => {
  return (
    <div>
    <Details courseName="Class 12 Commerce Economics" courseDetails="Understand the fundamentals of economics with our Class 12 Commerce Economics course! Covering Microeconomics and Macroeconomics, this course explores concepts like demand and supply, market structures, national income, inflation, and economic policies. With real-world case studies, analytical exercises, and practical applications, you'll develop a strong grasp of economic principles. Enroll now and enhance your understanding of the economy!"/>
      
    </div>
  )
}

export default Class12CommerceEconomics