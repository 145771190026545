import React from 'react'
import Details from '../../../../pages/Details/Details'

const Class8OptionalSubjects = () => {
  return (
    <div>
    <Details courseName="Class 8 Optional Subjects" courseDetails="  Explore new interests with our Class 8 Optional Subjects course! Covering subjects like Computer Science, General Knowledge, Environmental Studies, and more, this course enhances creativity and critical thinking. With interactive lessons and practical activities, students can develop new skills and expand their knowledge. Enroll now and make learning exciting!"/>
      
    </div>
  )
}

export default Class8OptionalSubjects