import React from 'react'
import Details from '../../../pages/Details/Details'

const NEETChemistry = () => {
  return (
    <div>
    <Details courseName="NEET Chemistry" courseDetails="  Master NEET Chemistry with our expert-designed course! Covering Physical, Organic, and Inorganic Chemistry, this course simplifies reactions, concepts, and problem-solving techniques. Get in-depth explanations, NEET-focused tips, and extensive practice questions to improve accuracy and speed. Enroll now and take a step closer to your medical dream!"/>
      
    </div>
  )
}

export default NEETChemistry