import React from 'react'
import Details from '../../../pages/Details/Details'

const English = () => {
  return (
    <div>
    <Details courseName=" English Spoken" courseDetails="  Master the art of speaking fluent English with our English Spoken course. Designed for all levels, this course helps you improve your vocabulary, pronunciation, and confidence in speaking. Whether you're preparing for an interview, a presentation, or simply want to communicate better, our expert instructors will guide you through every step. Join now and speak English with ease and clarity!"/>
      
    </div>
  )
}

export default English
